import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cancha',
    loadChildren: () => import('../../cancha/cancha.module').then(m => m.CanchaModule)
  },
  {
    path: 'caja',
    loadChildren: () => import('../../caja/caja.module').then(m => m.CajaModule)
  },
  {
    path: 'estacionamiento',
    loadChildren: () => import('../../estacionamiento/estacionamiento.module').then(m => m.EstacionamientoModule)
  }
];
