// export const URL_END_POINT_BASE = 'http://localhost:8082';
// export const URL_END_POINT_BASE = 'https://pidetucampo.com/api';
export const URL_END_POINT_BASE = '/api';

//AUTH
export const AUTENTICAR = "/autenticar/findByUser"

// Cancha
export const CANCHA_FINDALL = "/cancha/findAll";

// Hora
export const HORA_FINDALL = "/horadia/findAll";

// Dia
export const DIA_FINDBYID = "/diasemana/findById";
export const DIA_FIND_TODAY = "/diasemana/findByToDay";
export const DAY_FIND = "/diasemana/findByTfechDia";
export const DAYXSEMANA_FIND = "/diasemana/findByTsemanaId";

// Semana
export const SEMANA_FIND_TODAY = "/semana/findSemana";
export const SEMANA_FINDBYID = "/semana/findById";
export const SEMANA_FIND_PREV = "/semana/findByPrevSema";
export const SEMANA_FIND_NEXT = "/semana/findByNextSema";

// DataBody
export const SOLICITUD_FINDBYID = "/solicitud/findById";
export const DATA = "/solicitud/findAll";
export const SOLICITUD_SEMANA = "/solicitud/findAllBySemana";
export const INSERT_RESERVA = "/solicitud/insert";
export const BORRAR_RESERVA = "/solicitud/delete";
export const UPDATE_RESERVA = "/solicitud/update";

// DatabodyDetalle
export const DATA_DETALLE = "/solicitudDetalle/findByIdSolicitud";
// export const INSERT_RESERVA_DETALLE = "/solicitudDetalle/insert";

// Cliente
export const CLIENTE_INSERT = "/cliente/insert";
export const CLIENTE_LIKENOMB = "/cliente/findByIdempresaAndNombcliente";
export const CLIENTE_LIKENOMB_MUNI = "/cliente/findByIdempresaAndNombclienteAndMunicipalidad";
export const CLIENTE_FINDBYID = "/cliente/findById";

// TipoReserva
export const TIPORESERVA_FINDALL = "/tiporeserva/findAll";
export const TIPORESERVA_FINDBYID = "/tiporeserva/findById";

// TipoCliente
export const TIPOCLIENTE_FINDALL = "/tipocliente/findAll";

// Colaborador
export const COLABORADOR_FINDALL = "/colaborador/findAll";
export const COLABORADOR_FINDBYID = "/colaborador/findById";

// Medio de Pago
export const MEDIOPAGO_FINDALL = "/mediopago/findAll";
export const BANCO_FINDALL = "/banco/findAll";

// Reporte
export const REPORTE_DIARIO = "/planificador/reporteDiario";
export const REPORTE_SEMANAL = "/planificador/reporteSemanal";

// Motivo
export const MOTIVO_FINDALL = "/motivo/findAll";

// SubMotivo
export const SUBMOTIVO_FINDALL = "/submotivo/findByIdmotivo";

// Transaccion
export const TRANSACCION_FINDALLTRANSACCION = "/transaccion/findAllTransaccion";
export const TRANSACCION_FINDBYID = "/transaccion/findById";
export const TRANSACCION_INSERT = "/transaccion/insert";
export const TRANSACCION_UPDATE = "/transaccion/update";
export const TRANSACCION_DELETE = "/transaccion/delete";

// Estacionamiento diario
export const FINDALL_ESTACIONAMIENTODIARIO = "/estacionamiento/findAll";
export const INSERT_ESTACIONAMIENTODIARIO = "/estacionamiento/insert";
export const UPDATE_ESTACIONAMIENTODIARIO = "/estacionamiento/update";
export const REPORT_ESTACIONAMIENTODIARIO = "/estacionamiento/findReport";

// Reporte
export const FIND_TOTABYDAY = "/reporte/findReportIngresoCancha";
